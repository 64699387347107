import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Post from "../components/article"
import Layout from "../components/layout"
import FeaturedImage from "../components/featuredImage"

const ComponentName = () => {
  const data = useStaticQuery(graphql`
  {
    allMarkdownRemark(
      sort: {fields: frontmatter___created, order: DESC}
      filter: {frontmatter: {published: {eq: true}}}
      limit: 10
      ) {
        edges {
          node {
            id
            frontmatter {
              title
              created
              featuredImage {
                id
                uri {
                  publicURL
                  childCloudinaryAsset {
                    fluid(transformations: ["ar_4:3", "c_crop", "c_fill"]) {
                      ...CloudinaryAssetFluid
                    }
                  }
                  }
                  name {
                    relativePath
                  }
                }    
                featuredImageCreditLabel
                featuredImageCreditUri
                published
                season
                section
                slug
                tags
              }
            headings {
              id
            }
            html
            }
          }
        }
      }
      `)
      return (
        <Layout>
          <div className="container mx-auto p-2 sm:p-0 sm:grid sm:grid-cols-2 gap-4 mt-4">
              {data.allMarkdownRemark.edges.map(
                post =>(
                  <Link key={post.node.id} to={post.node.frontmatter.slug}>
                    <div>
                      {post.node.frontmatter.featuredImage?.uri && 
                        <div> 
                          <FeaturedImage
                            image={post.node.frontmatter.featuredImage.uri}
                            creditLabel={post.node.frontmatter.featuredImageCreditLabel}
                            creditUri={post.node.frontmatter.featuredImageCreditUri }
                          />
                        </div>
                      }
                    </div>
                    <Post post={post.node} thumbnail={true}/>
                  </Link>            
                  )
              )}
          </div>
        </Layout>
      )
    }
          
export default ComponentName
